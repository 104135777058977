<template>
  <div v-if="this.data">
    <CRow>
      <CCol sm="6" lg="6">
        <CButton
          class="insert-necrologio-button"
          size="lg"
          color="primary"
          @click="goToNecrologio()"
        >
          Inserisci Lutto
        </CButton>
        <br />
        <br />
      </CCol>

      <CCol sm="6" lg="6">
        <CButton class="insert-manifesto-button" size="lg" color="primary" @click="goToManifesto()">
          Inserisci Ricorrenza
        </CButton>
        <br />
        <br />
      </CCol>

      <CCol sm="12" lg="12">
        <label class="bold-text stats_label">Selezione annualità statistiche </label>
        <v-select :options="years" v-model="year" @input="getData(year)"> </v-select>
        <br />
      </CCol>

      <CCol sm="4" lg="4">
        <CWidgetDropdown
          color="gradient-primary"
          v-bind:header="visualizzazioni_pagina_azienda"
          text="Visualizzazioni totali pagina azienda"
        >
          <template #footer>
            <CChartLineSimple
              pointed
              class="mt-3 mx-3"
              :data-points="visualizzazioni_pagina_azienda_json"
              point-hover-background-color="primary"
              label="Visualizzazioni Pagina"
              :labels="labels"
            />
          </template>
        </CWidgetDropdown>
      </CCol>

      <CCol sm="4" lg="4">
        <CWidgetDropdown
          color="gradient-info"
          v-bind:header="visualizzazioni_manifesti"
          text="Visualizzazioni totali manifesti"
        >
          <template #footer>
            <CChartLineSimple
              pointed
              class="mt-3 mx-3"
              :data-points="visualizzazioni_manifesti_json"
              point-hover-background-color="info"
              :options="{ elements: { line: { tension: 0.00001 } } }"
              label="Visualizzazioni Manifesti"
              :labels="labels"
            />
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol sm="4" lg="4">
        <CWidgetDropdown
          color="gradient-warning"
          v-bind:header="visualizzazioni_necrologi"
          text="Visualizzazione totali necrologi"
        >
          <template #footer>
            <CChartLineSimple
              class="mt-3"
              background-color="rgba(255,255,255,.2)"
              :options="{ elements: { line: { borderWidth: 2.5 } } }"
              point-hover-background-color="warning"
              :data-points="visualizzazioni_necrologi_json"
              label="Visualizzazione Necrologi"
              :labels="labels"
            />
          </template>
        </CWidgetDropdown>
      </CCol>
      <!--      <CCol sm="3" lg="3">-->
      <!--        <CWidgetDropdown-->
      <!--            color="gradient-danger"-->
      <!--            v-bind:header="servizi_acquistati"-->
      <!--            text="Servizi totali acquistati"-->
      <!--        >-->
      <!--          <template #footer>-->
      <!--            <CChartBarSimple-->
      <!--                class="mt-3 mx-3"-->
      <!--                :data-points="servizi_acquistati_json"-->
      <!--                background-color="rgb(250, 152, 152)"-->
      <!--                label="Servizi Acquistati"-->
      <!--                :labels="labels"-->
      <!--            />-->
      <!--          </template>-->
      <!--        </CWidgetDropdown>-->
      <!--      </CCol>-->
    </CRow>

    <CCard>
      <CCardHeader> Totale manifesti caricati nel sistema: {{ manifesti_caricati }} </CCardHeader>
      <CCardBody>
        <CChartLine
          style="height: 250px"
          :datasets="[
            {
              data: manifesti_caricati_json,
              backgroundColor: '#3876bf',
              label: 'Caricati',
            },
          ]"
          :labels="labels"
          :options="{ maintainAspectRatio: false }"
        />
      </CCardBody>
    </CCard>

    <CRow>
      <CCol sm="4" lg="4">
        <CWidgetDropdown
          color="gradient-info"
          v-bind:header="visualizzazioni_manifesti_website"
          text="Visualizzazione Manifesti Sitoweb"
        >
          <template #footer>
            <CChartLineSimple
              pointed
              class="mt-4 mx-4"
              :data-points="visualizzazioni_manifesti_website_json"
              background-color="rgba(255,255,255,.4)"
              :options="{ elements: { line: { borderWidth: 2.5 } } }"
              point-hover-background-color="warning"
              label="Visualizzazione Manifesti Sitoweb"
              :labels="labels"
            />
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol sm="4" lg="4">
        <CWidgetDropdown
          color="gradient-info"
          v-bind:header="visualizzazioni_manifesti_app"
          text="Visualizzazione Manifesti App"
        >
          <template #footer>
            <CChartLineSimple
              pointed
              class="mt-4 mx-4"
              :data-points="visualizzazioni_manifesti_app_json"
              background-color="rgba(255,255,255,.2)"
              :options="{ elements: { line: { borderWidth: 2.5 } } }"
              point-hover-background-color="warning"
              label="Visualizzazione Manifesti App"
              :labels="labels"
            />
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol sm="4" lg="4">
        <CWidgetDropdown
          color="gradient-info"
          v-bind:header="visualizzazioni_manifesti_facebook"
          text="Visualizzazione Manifesti Facebook"
        >
          <template #footer>
            <CChartLineSimple
              pointed
              class="mt-4 mx-4"
              :data-points="visualizzazioni_manifesti_facebook_json"
              background-color="rgba(255,255,255,.2)"
              :options="{ elements: { line: { borderWidth: 2.5 } } }"
              point-hover-background-color="warning"
              label="Visualizzazione Manifesti Facebook"
              :labels="labels"
            />
          </template>
        </CWidgetDropdown>
      </CCol>
    </CRow>

    <CCard>
      <CCardHeader> Totale necrologi caricati nel sistema: {{ necrologi_caricati }} </CCardHeader>
      <CCardBody>
        <CChartLine
          style="height: 250px"
          :datasets="[
            {
              data: necrologi_caricati_json,
              backgroundColor: '#3876bf',
              label: 'Caricati',
            },
          ]"
          :labels="labels"
          :options="{ maintainAspectRatio: false }"
        />
      </CCardBody>
    </CCard>

    <CRow>
      <CCol sm="6" lg="6">
        <CWidgetDropdown
          color="gradient-warning"
          v-bind:header="visualizzazioni_necrologi_website"
          text="Visualizzazione Necrologi Sitoweb"
        >
          <template #footer>
            <CChartLineSimple
              pointed
              class="mt-6 mx-6"
              :data-points="visualizzazioni_necrologi_website_json"
              background-color="rgba(255,255,255,.2)"
              :options="{ elements: { line: { borderWidth: 2.5 } } }"
              point-hover-background-color="warning"
              label="Visualizzazione Necrologi Sitoweb"
              :labels="labels"
            />
          </template>
        </CWidgetDropdown>
      </CCol>

      <CCol sm="6" lg="6">
        <CWidgetDropdown
          color="gradient-warning"
          v-bind:header="visualizzazioni_necrologi_app"
          text="Visualizzazione Necrologi App"
        >
          <template #footer>
            <CChartLineSimple
              pointed
              class="mt-6 mx-6"
              :data-points="visualizzazioni_necrologi_app_json"
              background-color="rgba(255,255,255,.2)"
              :options="{ elements: { line: { borderWidth: 2.5 } } }"
              point-hover-background-color="warning"
              label="Visualizzazione Necrologi App"
              :labels="labels"
            />
          </template>
        </CWidgetDropdown>
      </CCol>
    </CRow>

    <CCard>
      <CCardHeader> Servizi totali acquistati: {{ servizi_acquistati }} </CCardHeader>
      <CCardBody>
        <CChartLine
          style="height: 250px"
          :datasets="[
            {
              data: servizi_acquistati_json,
              backgroundColor: '#3876bf',
              label: 'Servizi Aquistati',
            },
          ]"
          :labels="labels"
          :options="{ maintainAspectRatio: false }"
        />
      </CCardBody>
    </CCard>
  </div>
  <div v-else>
    <CAlert color="danger">
      <h5>Non ci sono dati da mostrare per la tua utenza</h5>
    </CAlert>
  </div>
</template>

<script>
const API_STATS_AGENZIA = '/dashboard/api/v0/statistiche/view';
const date = new Date();
import CChartBarExample from '@/views/charts/CChartBarExample';
import { CChartBar, CChartLine } from '@coreui/vue-chartjs';
import { CChartBarSimple, CChartLineSimple } from '../charts';

export default {
  name: 'WidgetsDropdown',
  components: { CChartBarExample, CChartLineSimple, CChartBarSimple, CChartBar, CChartLine },
  data() {
    return {
      data: [],
      stats: null,
      years: [date.getFullYear(), date.getFullYear() - 1, date.getFullYear() - 2],
      year: date.getFullYear(),
      labels: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ],

      visualizzazioni_pagina_azienda: '0',
      visualizzazioni_manifesti: '0',
      visualizzazioni_manifesti_website: '0',
      visualizzazioni_manifesti_app: '0',
      visualizzazioni_manifesti_facebook: '0',
      visualizzazioni_necrologi: '0',
      visualizzazioni_necrologi_website: '0',
      visualizzazioni_necrologi_app: '0',

      servizi_acquistati: '0',
      manifesti_caricati: '0',
      necrologi_caricati: '0',

      visualizzazioni_manifesti_website_json: [],
      visualizzazioni_manifesti_app_json: [],
      visualizzazioni_manifesti_facebook_json: [],
      visualizzazioni_pagina_azienda_json: [],
      visualizzazioni_manifesti_json: [],
      visualizzazioni_necrologi_json: [],
      visualizzazioni_necrologi_website_json: [],
      visualizzazioni_necrologi_app_json: [],
      servizi_acquistati_json: [],
      manifesti_caricati_json: [],
      necrologi_caricati_json: [],
    };
  },
  methods: {
    goToManifesto() {
      this.$router.push({
        name: 'manifesto',
      });
    },

    goToNecrologio() {
      this.$router.push({
        name: 'necrologio',
      });
    },
    async getData(year) {
      try {
        this.data = this.stats.find((el) => el.year === year);

        this.visualizzazioni_pagina_azienda = this.data.visualizzazioni_pagina_azienda.toString();
        this.visualizzazioni_manifesti = this.data.visualizzazioni_manifesti.toString();
        this.visualizzazioni_necrologi = this.data.visualizzazioni_necrologi.toString();
        this.servizi_acquistati = this.data.servizi_acquistati.toString();
        this.manifesti_caricati = this.data.manifesti_caricati.toString();
        this.necrologi_caricati = this.data.necrologi_caricati.toString();

        this.visualizzazioni_manifesti_website =
          this.data.visualizzazioni_manifesti_website.toString();
        this.visualizzazioni_manifesti_app = this.data.visualizzazioni_manifesti_app.toString();
        if (this.data.visualizzazioni_manifesti_app === null) {
          this.visualizzazioni_manifesti_app = '0';
        }
        this.visualizzazioni_manifesti_facebook =
          this.data.visualizzazioni_manifesti_facebook.toString();
        this.visualizzazioni_necrologi_website =
          this.data.visualizzazioni_necrologi_website.toString();
        this.visualizzazioni_necrologi_app = this.data.visualizzazioni_necrologi_app.toString();

        this.visualizzazioni_manifesti_website_json = _.values(
          this.data.visualizzazioni_manifesti_website_json
        );
        this.visualizzazioni_manifesti_app_json = _.values(
          this.data.visualizzazioni_manifesti_app_json
        );
        this.visualizzazioni_manifesti_facebook_json = _.values(
          this.data.visualizzazioni_manifesti_facebook_json
        );
        this.visualizzazioni_pagina_azienda_json = _.values(
          this.data.visualizzazioni_pagina_azienda_json
        );
        this.visualizzazioni_manifesti_json = _.values(this.data.visualizzazioni_manifesti_json);
        this.visualizzazioni_necrologi_json = _.values(this.data.visualizzazioni_necrologi_json);
        this.visualizzazioni_necrologi_website_json = _.values(
          this.data.visualizzazioni_necrologi_website_json
        );
        this.visualizzazioni_necrologi_app_json = _.values(
          this.data.visualizzazioni_necrologi_app_json
        );
        this.servizi_acquistati_json = _.values(this.data.servizi_acquistati_json);
        this.manifesti_caricati_json = _.values(this.data.manifesti_caricati_json);
        this.necrologi_caricati_json = _.values(this.data.necrologi_caricati_json);
      } catch (e) {
        console.log(e);
      }
    },
  },
  async created() {
    const res = await axios.get(API_STATS_AGENZIA);
    this.stats = res.data.results;
    await this.getData(date.getFullYear());
  },
};
</script>

<style>
button.btn.insert-necrologio-button.btn-primary.btn-lg {
  width: 100%;
  background-color: #f8a410 !important;
  font-weight: 500;
  height: 170px;
  font-size: 28px;
}

button.btn.insert-manifesto-button.btn-primary.btn-lg {
  width: 100%;
  height: 170px;
  font-weight: 500;
  font-size: 28px;
}

label.bold-text.stats_label {
  font-size: 14px !important;
  font-weight: 600;
}

.v-select.vs--single.vs--searchable {
  font-size: medium;
}

button.btn.insert-necrologio-button.btn-primary.btn-lg {
  width: 100%;
  background-color: #f8a410 !important;
  font-weight: 500;
  height: 170px;
  font-size: 28px;
}
</style>
