<template>
  <WidgetsDropdown />
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs';
import MainChartExample from './charts/MainChartExample';
import WidgetsBrand from './widgets/WidgetsBrand';
import WidgetsDropdown from './widgets/WidgetsDropdown';

export default {
  name: 'Dashboard',
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
    CChartBar,
  },
};
</script>
